import React, { useState } from "react";
import { useGlobal } from "../context/GlobalContext";
import { Redirect } from "react-router-dom";
import { Form, Input, Button, Row, Col, Typography, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import LoginPhoto from "../../images/loginphoto.jpg";
import aeropostLogo from "../../images/aeropost-dark-blue.png";
import { motion } from "framer-motion";

const { Title } = Typography;
const { Item } = Form;

export default function Login() {
  const { currentUser, loginWithGoogle } = useGlobal();
  const [domain, setDomain] = useState("");

  const verifyDomain = (value) => {
    var email = value.target.value;
    var formDomain = email.replace(/.*@/, "");
    if (formDomain.toLowerCase() === "aeropost.com") {
      setDomain(true);
    } else {
      setDomain(false);
    }
  };

  const emailRules = [
    {
      required: true,
      message: "Enter your email",
    },
    {
      type: "email",
      message: "Invalid email format",
    },
  ];

  const GIcon = () => (
    <svg
      viewBox="0 0 48 48"
      width={25}
      style={{
        background: "#fff",
        borderRadius: "50%",
        position: "absolute",
        left: "10%",
        top: "15%",
      }}
    >
      <clipPath id="g">
        <path d="M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z" />
      </clipPath>
      <g className="colors" clipPath="url(#g)">
        <path fill="#FBBC05" d="M0 37V11l17 13z" />
        <path fill="#EA4335" d="M0 11l17 13 7-6.1L48 14V0H0z" />
        <path fill="#34A853" d="M0 37l30-23 7.9 1L48 0v48H0z" />
        <path fill="#4285F4" d="M48 48L17 24l-4-3 35-10z" />
      </g>
    </svg>
  );

  return currentUser ? (
    <Redirect to="/" />
  ) : (
    <>
      <Row>
        <Col xs={{ span: 0 }} md={{ span: 12 }} lg={{ span: 16 }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2, duration: 1.5 }}
            style={{
              backgroundImage: `url(${LoginPhoto})`,
              backgroundRepeat: "no-repeat",
              height: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          />
        </Col>

        <Col
          xs={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 8 }}
          align="center"
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, duration: 2 }}
          >
            <div
              style={{
                textAlign: "center",
                alignItems: "center",
                display: "flex",
                justifyContent: "center",
                minHeight: "80vh",
              }}
            >
              <Space direction="vertical">
                <img src={aeropostLogo} alt="logo" width={300} />
                <Title type="secondary" level={3}>
                  Publi TV
                </Title>
                <div style={{ margin: 30 }} />

                <Form name="login">
                  <>
                    <Item
                      hasFeedback
                      name="email"
                      onChange={verifyDomain}
                      rules={emailRules}
                    >
                      <Input
                        allowClear
                        size="large"
                        prefix={<UserOutlined />}
                        placeholder="Enter your email"
                      />
                    </Item>

                    <Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.email !== currentValues.email
                      }
                    >
                      <Button
                        disabled={domain ? false : true}
                        block
                        icon={<GIcon />}
                        size="large"
                        type="primary"
                        onClick={() => loginWithGoogle()}
                      >
                        Continue with Google
                      </Button>
                    </Item>
                  </>
                </Form>
              </Space>
            </div>
          </motion.div>
        </Col>
      </Row>
    </>
  );
}
