import React from "react";
import { motion } from "framer-motion";
import { EditOutlined, DeleteOutlined, SyncOutlined } from "@ant-design/icons";
import {
  Progress,
  Table,
  Button,
  Typography,
  Space,
  Tooltip,
  notification,
  Spin,
} from "antd";

const { Text, Link } = Typography;

export const Permissions = (menu) => {
  let _home = ["admin", "agent"];
  let _users = ["admin"];
  let _gws = ["admin"];

  switch (menu) {
    case "home":
      return _home;

    case "users":
      return _users;

    case "gateways":
      return _gws;

    default:
      break;
  }
};

export const Denied = () => (
  <Motion>
    <img style={{ width: "100%", height: "70vh" }} src={Image} alt="notfound" />
  </Motion>
);

export const Spinner = () => (
  <div align="center">
    <motion.div
      initial={{ y: 140, opacity: 0 }}
      animate={{ y: 110, opacity: 1 }}
      transition={{ delay: 0, type: "spring", stiffness: 120 }}
    >
      <Spin size="large" />
    </motion.div>
  </div>
);

export function CustomTable(props) {
  return (
    <Table
      title={() =>
        props.showButton ? (
          <div align="center">
            <Space size={6}>
              <Button
                loading={props.send}
                onClick={props.handleRefresh}
                size="middle"
                type="primary"
                icon={<SyncOutlined />}
                htmlType="submit"
              >
                Refresh data
              </Button>
            </Space>
          </div>
        ) : null
      }
      size="large"
      columns={props.columns}
      loading={{
        spinning: props.send,
        indicator: <Spinner size="large" />,
      }}
      dataSource={props.data}
      scroll={{ x: 900 }}
      sticky
    />
  );
}

export const CustomHeader = (props) => (
  <div align="center" style={{ textAlign: "center" }}>
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 20, opacity: 1 }}
      transition={{ delay: 0, type: "tween", stiffness: 120 }}
      style={{ padding: "10px 0px 40px 0px" }}
    >
      <Typography.Title
        style={{ color: "#304189" }}
        align="center"
        title={props.label}
        ellipsis
        level={2}
      >
        {props.label}
      </Typography.Title>
    </motion.div>
  </div>
);

export const Notification = (placement, type, message, description) => {
  notification.config({
    placement: "topLeft",
    duration: 3,
    maxCount: 1,
  });

  notification[type]({
    message,
    description,
  });
};

export const Copyright = () => (
  <Space size="small" align="start">
    <Text type="secondary" style={{ fontSize: 10 }}>
      {"© "} {new Date().getFullYear()}{" "}
    </Text>
    <Link
      style={{ fontSize: 10 }}
      href="https://aeropost.com/"
      target="https://aeropost.com/"
    >
      Aeropost Internacional Services, INC.
    </Link>
  </Space>
);

export const Motion = ({ children }) => {
  const MotionDiv = {
    hidden: {
      opacity: 0,
      y: 50,
    },
    visible: {
      y: 20,
      opacity: 1,
      transition: {
        delay: 0,
        type: "spring",
        stiffness: 200,
      },
    },
  };
  return (
    <motion.div variants={MotionDiv} initial={"hidden"} animate={"visible"}>
      {children}
    </motion.div>
  );
};

export const ProgressBar = (delay) => (
  <motion.div
    initial={{ y: 50, opacity: 0 }}
    animate={{ y: 14, opacity: 1 }}
    transition={{ delay: delay ? delay : 1, type: "spring", stiffness: 100 }}
  >
    <Progress
      strokeWidth={"1.5vh"}
      status="active"
      style={{ width: "30%" }}
      showInfo={false}
      strokeColor="#9FC6E6"
      percent={100}
    />
  </motion.div>
);

//#region

export function SiteTable({ send, data }) {
  const columns = [
    {
      width: "12%",
      dataIndex: "_id",
      fixed: "left",
      render: (record, index) => (
        <Space direction="horizontal" size={20}>
          <Tooltip placement="left" title="Editar">
            <Button
              type="primary"
              shape="round"
              icon={<EditOutlined />}
              size={"small"}
              // onClick={() => handleEdit(index)}
            />
          </Tooltip>
          <Tooltip placement="right" title="Eliminar">
            <Button
              type="dashed"
              shape="round"
              danger
              icon={<DeleteOutlined />}
              size={"small"}
              // onClick={() => showConfirm(record)}
            />
          </Tooltip>
        </Space>
      ),
    },
    {
      title: "Item",
      dataIndex: "name",
    },
  ];

  return (
    <CustomTable
      // handleCreate={props.handleCreate}
      columns={columns}
      send={send}
      data={data}
      showButton
    />
  );
}

//#endregion
