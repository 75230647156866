import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import LoadingBar from "react-top-loading-bar";
import { Row, Col, Alert, Typography, Carousel, Button } from "antd";
import _ from "lodash";
import { TextLoop } from "react-text-loop-next";
import Marquee from "react-fast-marquee";
import axios from "axios";
import { useGlobal } from "../context/GlobalContext";
import { DeleteMethod, GetMethod, PatchMethod } from "../misc/CRUD";
import { useHistory } from "react-router-dom";
import { Spinner } from "../misc/Generic";
import { storage } from "../context/firebase";
import moment from "moment";

const { Title, Text } = Typography;

export default function Display() {
  const location = useLocation();
  const [data, setData] = useState(location.state);
  const [refresh, setRefresh] = useState(false);
  const [isImage, setIsImage] = useState(true);
  const [finishVideo, setFinishVideo] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [_id] = useState(window.location.search.split("=")[1]);
  const [getNewData, setGetNewData] = useState(false);
  const [firstTime, setFirstTime] = useState(false);
  const { API } = useGlobal();

  const handleDeleteMedia = async (_media, gw, _data) => {
    if (!_.isEmpty(_data)) {
      await DeleteMethod(`${API}media/${_media.uid}`)
        .then(async () => {
          await storage
            .ref()
            .child(`gateways/${gw}/${_media.filedata.name}`)
            .delete();
        })
        .then(async () => {
          _data.forEach((loc) => {
            if (!_.isEmpty(loc.media)) {
              loc.media.forEach((media) => {
                if (media.url === _media.url) {
                  const values = {
                    gateway: gw,
                    id: loc.id,
                    mediaName: _media.filedata.name,
                    mediaURL: _media.url,
                    action: "delete",
                    type: _media.filedata.type,
                  };
                  PatchMethod(`${API}media`, values)
                    .then((res) => {
                      console.log("Media deleted =>", _media.filedata.name);
                      refreshInfo();
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              });
            }
          });
        });
    }
  };

  const getMedia = (_gw, _locations) => {
    GetMethod(`${API}media/${_gw}`).then((res) => {
      res.forEach((e) => {
        if (e.destroy && moment().isSame(e.destroy, "day")) {
          handleDeleteMedia(e, _gw, _locations);
        }
      });
    });
  };

  const refreshInfo = async () => {
    await axios
      .get(`${API}gateways/${_id}`)
      .then((res) => {
        const _locations = res.data.locations;
        _locations.forEach((location) => {
          if (location.id === _id) {
            getMedia(res.data.gateway, res.data.locations);
            const locationProps = { ...location, gateway: res.data.gateway };
            if (!_.isEqual(locationProps, data)) {
              setData({ ...location, gateway: res.data.gateway });
            }
          }
        });
      })
      .catch((_) => {
        console.log(_);
      })
      .finally(() => {
        setGetNewData(false);
      });
  };

  // useEffect(() => void setInterval(() => refreshInfo(), 90000), []);
  useEffect(() => void setInterval(() => refreshInfo(), 300000), []);

  //! run first time

  useEffect(() => {
    if (!firstTime) {
      refreshInfo();
    }
  }, []);

  return (
    <>
      {data && (
        <>
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              left: 0,
              right: 0,
              top: data.bannerPosition === "top" ? "0px" : "auto",
              bottom: data.bannerPosition === "bottom" ? "0px" : "auto",
            }}
          >
            {data.showBanner &&
              (data.bannerType === "marquee" ? (
                <Alert
                  type="info"
                  banner
                  showIcon={false}
                  style={{ backgroundColor: data.bgColor.hex }}
                  message={
                    <Marquee
                      speed={data.bannerSpeed}
                      gradient={false}
                      style={{
                        fontSize: data.bannerFontSize,
                        color: data.textColor.hex,
                      }}
                    >
                      {data.bannerDescription}
                    </Marquee>
                  }
                />
              ) : data.bannerType === "loop" ? (
                <Alert
                  type="info"
                  showIcon={false}
                  banner
                  style={{ backgroundColor: data.bgColor.hex }}
                  message={
                    <TextLoop mask adjustingSpeed={data.bannerSpeed}>
                      {data.bannerLoopText.map((text, i) => (
                        <div
                          key={i}
                          style={{
                            fontSize: data.bannerFontSize,
                            color: data.textColor.hex,
                          }}
                        >
                          {text.description}
                        </div>
                      ))}
                    </TextLoop>
                  }
                />
              ) : null)}
          </div>

          {!_.isEmpty(data.media) ? (
            <Carousel
              fade={data.fade}
              pauseOnHover={false}
              autoplaySpeed={data.refreshRate * 1000}
              dots={false}
              autoplay={true}
            >
              {data &&
                data.media.map((item, i) => (
                  <div key={i}>
                    {item.type.includes("video") ? (
                      <ReactPlayer
                        playing={true}
                        loop={true}
                        muted
                        width="100vw"
                        height="100vh"
                        url={item.url}
                      />
                    ) : (
                      <div
                        style={{
                          backgroundImage: `url(${item.url})`,
                          backgroundRepeat: "no-repeat",
                          height: "100vh",
                          backgroundSize: "cover",
                          backgroundPosition: "contain",
                        }}
                      />
                    )}
                  </div>
                ))}
            </Carousel>
          ) : (
            <div
              style={{
                backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/digital-signage-b21f0.appspot.com/o/default.png?alt=media&token=8aa8db1c-4103-41ad-8f8a-eff25136db85)`,
                backgroundRepeat: "no-repeat",
                height: "100vh",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
          )}
        </>
      )}
    </>
  );
}
