import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PatchMethod } from "../misc/CRUD";
import { Motion, Spinner } from "../misc/Generic";
import { CustomTable, Notification } from "../misc/Generic";
import moment from "moment";
import { CustomHeader } from "../misc/Generic";
import Highlighter from "react-highlight-words";
import {
  EditOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  Select,
  Tag,
  Modal,
  Space,
  Button,
  Form,
  Col,
  Row,
  Input,
  Tooltip,
  Avatar,
  Typography,
  Divider,
  Alert,
} from "antd";

const { Option } = Select;
const { Item } = Form;
const { Text } = Typography;

export default function Users() {
  const { API, currentUser } = useGlobal();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // loading state of the hole page
  const [send, setSend] = useState(true);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [editData, seteditData] = useState();
  const [form] = Form.useForm();
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [gateways, setGateways] = useState([]);

  //#region   //? =---> MISC

  const getData = () => {
    setSend(true);
    GetMethod(`${API}users/`)
      .then((res) => {
        GetMethod(`${API}gateways/`).then((res) => {
          setGateways(res);
        });

        setData(res);
        setLoading(true);
        setSend(false);
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
        setSend(false);
      });
  };

  useEffect(() => {
    let isCancelled = false;

    GetMethod(`${API}users/`)
      .then((_users) => {
        GetMethod(`${API}gateways/`).then((_gws) => {
          if (!isCancelled) {
            setGateways(_gws);
            setData(_users);
            setLoading(true);
            setSend(false);
          }
        });
      })
      .catch((err) => {
        setError(err);
        setLoading(true);
        setSend(false);
      });

    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreate = () => {
    seteditData("");
    form.resetFields();
    setShowModal(true);
  };

  const handleRefresh = () => {
    getData();
  };

  //#endregion

  //#region   //? =---> UPDATE || POST in onFinish())
  const handleEdit = (rowData) => {
    setShowModal(true);
    seteditData(rowData);
  };

  // #region //? =---> Custom Form
  const CustomizedForm = ({ onChange, fields }) => {
    const onFinish = async (values) => {
      setSend(true);

      if (editData) {
        //#region //? =---> UPDATE

        values = {
          ...values,
          email: editData.email,
        };

        // Compare 2 arrays
        const { email, firstName, lastName, role, status } = editData;

        if (
          !_.isEqual(
            {
              email,
              firstName,
              lastName,
              role,
              status,
            },
            values
          )
        ) {
          setShowModal(false);

          values = {
            ...values,
            email: editData.email,
          };

          PatchMethod(`${API}users/${editData._id}`, values)
            .then((res) => {
              setSend(false);
              setData(res.allUsers);
              Notification(" topRight", "success", "User has been updated!");
            })
            .catch((err) => {
              setError(err);
              setSend(false);
            });
        } else {
          setShowModal(false);
          setSend(false);
        }
        //#endregion
      }
      //#endregion
    };

    return (
      <>
        <div align="center" style={{ position: "relative", top: -50 }}>
          {editData.photoURL ? (
            <Avatar
              size={100}
              src={editData.photoURL}
              style={{ boxShadow: "0px 3px 10px 3px rgba(0,0,0,0.2)" }}
            />
          ) : (
            <Avatar
              style={{
                backgroundColor: "#8DC7E8",
                verticalAlign: "middle",
              }}
              size={100}
            >
              <span style={{ fontSize: 16 }}>
                {`${editData.firstName.substring(
                  0,
                  1
                )}${editData.lastName.substring(0, 1)}`}
              </span>
            </Avatar>
          )}
        </div>
        <div style={{ position: "relative", top: -30 }}>
          <Divider />
          <Form
            form={form}
            onFinishFailed={() =>
              Notification(" topRight", "error", "Please check all the fields")
            }
            onFinish={onFinish}
            layout="vertical"
            fields={fields}
          >
            <Row gutter={[24, 3]}>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Item
                  hasFeedback
                  name="firstName"
                  label="First Name"
                  rules={[{ required: true }]}
                >
                  <Input
                    allowClear
                    disabled={
                      editData
                        ? editData.email.replace(/.*@/, "") === "aeropost.com"
                          ? true
                          : false
                        : false
                    }
                    style={{ borderRadius: 10 }}
                  />
                </Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Item
                  hasFeedback
                  name="lastName"
                  label="Last Name"
                  rules={[{ required: true }]}
                >
                  <Input
                    allowClear
                    disabled={
                      editData
                        ? editData.email.replace(/.*@/, "") === "aeropost.com"
                          ? true
                          : false
                        : false
                    }
                    style={{ borderRadius: 10 }}
                  />
                </Item>
              </Col>

              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Item
                  hasFeedback
                  name="role"
                  label="Role"
                  rules={[{ required: true }]}
                >
                  <Select allowClear style={{ minWidth: "100%" }}>
                    <Option value="admin">Admin</Option>
                    <Option value="agent">Agent</Option>
                  </Select>
                </Item>
              </Col>

              {editData && (
                <Col xs={{ span: 24 }} md={{ span: 12 }}>
                  <Item
                    hasFeedback
                    name="status"
                    label="Status"
                    rules={[
                      {
                        required: editData ? true : false,
                      },
                    ]}
                  >
                    <Select allowClear disabled={editData ? false : true}>
                      <Option value="new">New</Option>
                      <Option value="active">Active</Option>
                      <Option value="inactive">Disabled</Option>
                    </Select>
                  </Item>
                </Col>
              )}
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item
                  name="gateway"
                  label="Gateway"
                  rules={[{ required: true }]}
                >
                  <Select
                    maxTagCount="responsive"
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {gateways.map((item, i) =>
                      item.enabled ? (
                        <Option key={i} value={item.gateway}>
                          {`${item.gateway} - ${item.country}`}
                        </Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Item
                  hasFeedback
                  name="email"
                  label="Email address"
                  rules={[
                    {
                      required: true,
                    },
                    {
                      type: "email",
                    },
                    {
                      pattern: !editData ? "^(?!.*aeropost.com).*$" : "",
                      message: "Aeropost users must be logged in with Google",
                    },
                  ]}
                >
                  <Input
                    allowClear
                    disabled={
                      editData
                        ? editData.email.replace(/.*@/, "") === "aeropost.com"
                          ? true
                          : true
                        : false
                    }
                  />
                </Item>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  };

  const EditForm = () => {
    const [fields, setFields] = useState([
      {
        name: "firstName",
        value: editData ? editData.firstName : null,
      },

      {
        name: "lastName",
        value: editData ? editData.lastName : null,
      },
      {
        name: "segundoApellido",
        value: editData ? editData.segundoApellido : null,
      },
      {
        name: "role",
        value: editData ? editData.role : null,
      },
      {
        name: "email",
        value: editData ? editData.email : null,
      },
      {
        name: "status",
        value: editData ? editData.status : null,
      },
      {
        name: "gateway",
        value: editData ? editData.gateway : null,
      },
    ]);

    return (
      <CustomizedForm
        fields={fields}
        onChange={(newFields) => {
          setFields(newFields);
        }}
      />
    );
  };
  //#endregion

  //#endregion

  //#region  //? =---> Table props

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, fieldName) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`${fieldName}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          />
          <Button
            icon={<DeleteOutlined />}
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          />
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",

    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      align: "center",
      width: 70,
      dataIndex: "_id",
      fixed: "left",
      render: (index, record) =>
        record.photoURL ? (
          <Avatar
            src={record.photoURL}
            style={{ boxShadow: "1px 1px 5px 1px rgba(0,0,0,0.2)" }}
          />
        ) : (
          <Avatar
            style={{
              backgroundColor: "#8DC7E8",
              verticalAlign: "middle",
            }}
            size={32}
          >
            <span style={{ fontSize: 16 }}>
              {`${record.firstName.substring(0, 1)}${record.lastName.substring(
                0,
                1
              )}`}
            </span>
          </Avatar>
        ),
    },
    {
      ellipsis: true,
      title: "Name",
      align: "center",
      dataIndex: "fullName",
      ...getColumnSearchProps("fullName", "Name"),
      render: (index, record) => (
        <Text ellipsis>{`${record.firstName} ${record.lastName} `}</Text>
      ),
      sorter: (a, b) => a.fullName.length - b.fullName.length,
      sortDirections: ["descend", "ascend"],
    },
    {
      ellipsis: true,
      title: "Email",
      ...getColumnSearchProps("email", "Email"),
      dataIndex: "email",
      align: "center",
      sorter: (a, b) => a.email.length - b.email.length,
      sortDirections: ["descend", "ascend"],
    },
    // {
    //   title: "Gateway",
    //   // ...getColumnSearchProps("gateway", "Gateway"),
    //   dataIndex: "gateway",
    //   align: "center",
    //   // sorter: (a, b) => a.gateway.length - b.gateway.length,
    //   // sortDirections: ["descend", "ascend"],
    //   render: (_, record) => record.gateway,
    // },
    {
      align: "center",
      title: "Role",
      sorter: (a, b) => a.role.length - b.role.length,
      sortDirections: ["descend", "ascend"],
      render: (item) =>
        item.role === "admin" ? (
          <Tag color="cyan">Admin</Tag>
        ) : item.role === "agent" ? (
          <Tag color="blue">Agent</Tag>
        ) : null,
      filters: [
        {
          text: "Admin",
          value: "admin",
        },
        {
          text: "Agent",
          value: "agent",
        },
      ],
      filterMultiple: true,
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },

    {
      title: "Status",
      align: "center",

      filters: [
        {
          text: "Active",
          value: "active",
        },
        {
          text: "New",
          value: "new",
        },
        {
          text: "Disabled",
          value: "inactive",
        },
      ],
      defaultFilteredValue: ["active", "new"],
      filterMultiple: true,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
      sorter: (a, b) => a.status.length - b.status.length,
      sortDirections: ["descend", "ascend"],
      render: (item) => (
        <>
          {item.status === "active" ? (
            <Tag color="geekblue">Active</Tag>
          ) : item.status === "inactive" ? (
            <Tag color="volcano">Disabled</Tag>
          ) : item.status === "new" ? (
            <Tooltip title="Waiting for permissions">
              <Tag icon={<ExclamationCircleOutlined />} color="warning">
                New
              </Tag>
            </Tooltip>
          ) : null}

          {!item.emailVerified && (
            <Tag icon={<CloseCircleOutlined />} color="error">
              Not verified
            </Tag>
          )}
        </>
      ),
    },

    {
      ellipsis: true,
      title: "Last seen",
      align: "center",
      sorter: (a, b) => moment(a.lastSignInTime) - moment(b.lastSignInTime),

      render: (item) =>
        item.lastSignInTime ? (
          <Tooltip
            placement="top"
            title={moment(item.lastSignInTime).format("LLL")}
          >
            <Text ellipsis>
              {moment(item.lastSignInTime).fromNow().charAt(0).toUpperCase() +
                moment(item.lastSignInTime).fromNow().slice(1)}
            </Text>
          </Tooltip>
        ) : (
          "Never"
        ),
    },

    {
      align: "center",
      width: 100,
      dataIndex: "_id",
      fixed: "right",

      render: (record, index) => (
        <Row justify="space-around" align="middle" gutter={[0]}>
          <Col>
            <Tooltip placement="top" title="Edit">
              <Button
                type="primary"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => handleEdit(index)}
                disabled={index.email === currentUser.email ? true : false}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
  ];
  //#endregion

  return (
    <>
      {error ? (
        <>
          <Divider />
          <Alert
            message="Error"
            showIcon
            description={error.message}
            type="error"
          />
        </>
      ) : (
        <>
          <CustomHeader label="User Management" />
          {!data ? (
            <Spinner />
          ) : (
            <>
              <Motion>
                <CustomTable
                  handleCreate={handleCreate}
                  handleRefresh={handleRefresh}
                  columns={columns}
                  send={loading ? send : send}
                  data={data}
                  showButton={true}
                />

                <Modal
                  width={700}
                  // title={editData ? "Edit user" : "Create new user"}
                  onCancel={() => setShowModal(false)}
                  visible={showModal}
                  onOk={() => {
                    form.submit();
                  }}
                >
                  <div align="center">
                    <EditForm />
                  </div>
                </Modal>
              </Motion>
            </>
          )}
        </>
      )}
    </>
  );
}
