import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import _ from "lodash";
import LoadingBar from "react-top-loading-bar";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PatchMethod, DeleteMethod } from "../misc/CRUD";
import { UploadPhoto } from "../misc/UploadPhoto";
import { storage } from "../context/firebase";
import moment from "moment";
import { motion } from "framer-motion";
import { Notification } from "../misc/Generic";
import { CompactPicker } from "react-color";
import { TextLoop } from "react-text-loop-next";
import Marquee from "react-fast-marquee";
import {
  EditOutlined,
  UploadOutlined,
  DeleteOutlined,
  LinkOutlined,
  FileImageOutlined,
  FileImageTwoTone,
  VideoCameraTwoTone,
  InfoCircleOutlined,
  SettingOutlined,
  PlaySquareOutlined,
  ClearOutlined,
  ThunderboltOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  SaveFilled,
  ClockCircleOutlined,
} from "@ant-design/icons";
import {
  Radio,
  Input,
  InputNumber,
  Alert,
  Skeleton,
  Image,
  Select,
  Modal,
  Space,
  Button,
  Form,
  Col,
  Row,
  message,
  Tooltip,
  Card,
  Divider,
  Carousel,
  Typography,
  Popconfirm,
  Tabs,
  Popover,
  Avatar,
  Switch,
  Drawer,
  Slider,
  DatePicker,
} from "antd";

const { Item, List } = Form;
const { Text, Title } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const contentStyle = {
  height: "160px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
};

export default function Home() {
  //#region ------------------------- Hooks -------------------------
  const { API, userProps } = useGlobal();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [totalGateways, setTotalGateways] = useState(0);
  const [totalLocations, setTotalLocations] = useState(0);
  const [totalMedia, setTotalMedia] = useState(0);
  const [error, setError] = useState("");
  const [currentGateway, setCurrentGateway] = useState(userProps.gateway[0]);
  const [media, setMedia] = useState([]);
  const [openLocationModal, setOpenLocationModal] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [editLocationModal, setEditLocationModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [fillSelect, setFillSelect] = useState([]);
  const [editingLocation, setEditingLocation] = useState();
  const [updating, setUpdating] = useState(false);
  const [form] = Form.useForm();
  // const [dateForm] = Form.useForm();

  const ref = useRef(null);

  //#endregion

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    GetMethod(`${API}gateways/`)
      .then((res) => {
        if (isMounted) {
          setData(res);
          findGateway(res);
          getMediaAndGateway(userProps.gateway[0]);
          countActiveDevices(res);

          //? Get location from the first gateway
          const filteredData = res.filter(
            ({ enabled, gateway }) =>
              enabled && gateway === userProps.gateway[0]
          );

          setSelectedLocations(filteredData[0].locations);

          setLoading(false);
        }
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    GetMethod(`${API}gateways/`)
      .then((res) => {
        if (isMounted) {
          setData(res);
          findGateway(res);
          countActiveDevices(res);
          setLoading(false);

          const filteredData = res.filter(
            ({ enabled, gateway }) => enabled && gateway === currentGateway
          );

          setSelectedLocations(filteredData[0].locations);
        }
      })
      .catch((err) => {
        setError(err);
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updating]);

  const findGateway = (res) => {
    let arr = [];
    res.forEach((item) => {
      userProps.gateway.forEach((gw, i) => {
        if (item.gateway === gw && item.enabled) {
          arr.push({
            gateway: item.gateway,
            country: item.country,
            locations: item.locations,
            enabled: item.enabled,
            _id: item._id,
          });
        }
      });
    });

    setFillSelect(arr);
  };

  const countActiveDevices = (_data) => {
    let countGws = 0;
    let countLocs = 0;
    let _locations = [];

    _data.forEach((element) => {
      if (element.enabled) countGws++;
      if (element.locations) {
        _locations.push(element.locations); //? get all locations
        countLocs = countLocs + element.locations.length;
      }
    });

    setTotalGateways(((countGws * 100) / _data.length).toFixed(0));
    setTotalLocations(countLocs);
  };

  const getMediaAndGateway = (_gw) => {
    setLoading(true);
    ref.current.continuousStart();

    GetMethod(`${API}media/${_gw}`)
      .then((res) => {
        setMedia(res);
        setTotalMedia(res.length);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        ref.current.complete();
        setLoading(false);
      });
  };

  const changeCurrentGateway = (value) => {
    const filteredData = data.filter(
      ({ enabled, gateway }) => enabled && gateway === value
    );

    if (!_.isEmpty(filteredData)) {
      setSelectedLocations(filteredData[0].locations);
      setCurrentGateway(value);
      getMediaAndGateway(value);
    }
  };

  const handleEditMedia = (_media) => {
    setSelectedMedia(_media);
    form.setFieldsValue({
      destroy: moment(_media.destroy),
      willBeDestroyed: _media.destroy ? true : false,
    });
    setOpenLocationModal(true);
  };

  const handleDeleteMedia = async (_media) => {
    ref.current.continuousStart();

    let _data = data.filter(({ gateway }) => gateway === currentGateway);
    _data = _data[0];

    await DeleteMethod(`${API}media/${_media.uid}`)
      .then(async () => {
        await storage
          .ref()
          .child(`gateways/${currentGateway}/${_media.filedata.name}`)
          .delete();
        message.success("File deleted successfully", 5);
      })
      .then(async () => {
        _data.locations.forEach((loc) => {
          if (!_.isEmpty(loc.media)) {
            loc.media.forEach((media) => {
              if (media.url === _media.url) {
                const values = {
                  gateway: currentGateway,
                  id: loc.id,
                  mediaName: _media.filedata.name,
                  mediaURL: _media.url,
                  action: "delete",
                  type: _media.filedata.type,
                };

                PatchMethod(`${API}media`, values)
                  .then((res) => {
                    setData(res);
                    message.success(`Media removed from ${loc.name}`, 5);
                  })
                  .catch((err) => {
                    setError(err);
                  });
              }
            });
          }
        });
      })
      .finally(() => {
        ref.current.complete();
        getMediaAndGateway(currentGateway);
      });
  };

  function bytesToSize(bytes) {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  }

  const handleTabChange = (key) => {
    key === "1" && getMediaAndGateway(currentGateway);
    key === "2" && getMediaAndGateway(currentGateway);
  };

  const quickRemoveAndAssign = async (action) => {
    setUpdating(true);
    ref.current.continuousStart();

    const values = {
      data: selectedLocations,
      media: selectedMedia,
      action,
    };

    await PatchMethod(`${API}media/multiple`, values)
      .then((res) => {
        setData(res);
        getMediaAndGateway(currentGateway);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        ref.current.complete();
        setUpdating(false);
      });
  };

  const assignAndRemoveMedia = async (action, location) => {
    setUpdating(true);
    ref.current.continuousStart();

    const values = {
      gateway: currentGateway,
      id: location.id,
      position: location.position,
      mediaName: selectedMedia.filedata.name,
      type: selectedMedia.filedata.type,
      mediaURL: selectedMedia.url,
      action,
    };

    await PatchMethod(`${API}media`, values)
      .then((res) => {
        setData(res);
        getMediaAndGateway(currentGateway);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        ref.current.complete();
        setUpdating(false);
      });
  };

  const clearAllMedia = (item) => {
    setUpdating(true);
    ref.current.continuousStart();

    item.media.forEach((media) => {
      const values = {
        gateway: currentGateway,
        id: item.id,
        position: item.position,
        mediaName: media.name,
        type: media.type,
        mediaURL: media.url,
        action: "delete",
      };
      PatchMethod(`${API}media`, values)
        .then((res) => {
          getMediaAndGateway(currentGateway);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          ref.current.complete();
          setUpdating(false);
        });
    });
  };

  const onFinish = async (values) => {
    ref.current.continuousStart();

    const gwToUpdate = fillSelect.find(
      (item) => item.gateway === currentGateway
    );

    gwToUpdate.locations.forEach((item, i) => {
      if (item.id === editingLocation.id) {
        gwToUpdate.locations[i] = {
          ...item,
          fade: values.fade,
          refreshRate: values.refreshRate,
          showBanner: values.showBanner,
          bannerType: values.bannerType,
          bannerSpeed: values.bannerSpeed,
          bannerPosition: values.bannerPosition,
          bannerFontSize: values.bannerFontSize,
          bannerDescription: values.bannerDescription,
          bannerLoopText: values.bannerLoopText,
          bgColor: values.bgColor,
          textColor: values.textColor,
        };
      }
    });

    PatchMethod(`${API}gateways/${gwToUpdate._id}`, gwToUpdate)
      .then((res) => {
        setData(res);
        Notification(
          "bottomLeft",
          "success",
          "Device updated successfully, changes will be applied after 5 minutes"
        );
      })
      .catch((err) => {
        setError(err);
        console.log(err);
      })
      .finally(() => {
        ref.current.complete();
      });
  };

  const ShowAlert = () => {
    return (
      <>
        <Divider />
        <Alert
          message="Error"
          showIcon
          description={error.message}
          type="error"
        />
      </>
    );
  };

  const handleEditLocation = (item) => {
    setEditLocationModal(true);
    setEditingLocation(item);

    form.setFieldsValue({
      refreshRate: item.refreshRate,
      fade: item.fade,
      showBanner: item.showBanner,
      bannerType: item.bannerType,
      bannerSpeed: item.bannerSpeed,
      bannerPosition: item.bannerPosition,
      bannerFontSize: item.bannerFontSize,
      bannerDescription: item.bannerDescription,
      bannerLoopText: item.bannerLoopText,
      bgColor: item.bgColor,
      textColor: item.textColor,
    });
  };

  const FakeCarousel = () => {
    return (
      <Space>
        <div style={{ maxWidth: 150 }}>
          <Carousel fade autoplay pauseOnHover={false} autoplaySpeed={1700}>
            <div>
              <h3 style={contentStyle}>Fade on #1</h3>
            </div>
            <div>
              <h3 style={contentStyle}>Fade on #2</h3>
            </div>
            <div>
              <h3 style={contentStyle}>Fade on #3</h3>
            </div>
          </Carousel>
        </div>
        <div style={{ maxWidth: 150 }}>
          <Carousel
            fade={false}
            autoplay
            pauseOnHover={false}
            autoplaySpeed={1700}
          >
            <div>
              <h3 style={contentStyle}>Fade off #1</h3>
            </div>
            <div>
              <h3 style={contentStyle}>Fade off #2</h3>
            </div>
            <div>
              <h3 style={contentStyle}>Fade off #3</h3>
            </div>
          </Carousel>
        </div>
      </Space>
    );
  };

  const handleDestroy = (_val) => {
    console.log(selectedMedia);

    const values = {
      ...selectedMedia,
      action: "auto-destroy",
      destroy: _val.destroy,
    };

    console.log("values: =>", values);

    PatchMethod(`${API}media`, values)
      .then((res) => {
        // setData(res);
        console.log(res);
        message.success(`Auto destroy configured`, 5);
        getMediaAndGateway(currentGateway);
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <>
      <LoadingBar ref={ref} color="#1890ff" height={3} shadow={false} />
      {error && <ShowAlert />}
      {data && (
        <>
          {selectedMedia && (
            <Modal
              onCancel={() => setOpenLocationModal(false)}
              visible={openLocationModal}
              width={1400}
              footer={[
                <Space key={5} size={25}>
                  <Tooltip key={1} title="Assign media to all devices">
                    <Button
                      type="dashed"
                      onClick={() => {
                        quickRemoveAndAssign("add");
                      }}
                      icon={<ThunderboltOutlined />}
                      loading={updating}
                    >
                      Assign all
                    </Button>
                  </Tooltip>

                  <Tooltip key={2} title="Remove media from all devices">
                    <Button
                      loading={updating}
                      type="dashed"
                      onClick={() => {
                        quickRemoveAndAssign("delete");
                      }}
                      icon={<ClearOutlined />}
                    >
                      Remove all
                    </Button>
                  </Tooltip>

                  <Button
                    onClick={() => setOpenLocationModal(false)}
                    type="primary"
                  >
                    Close
                  </Button>
                </Space>,
              ]}
            >
              <div align="center" style={{ position: "relative", top: -50 }}>
                <div align="center">
                  {selectedMedia.filedata.type.includes("image") ? (
                    <Image
                      src={selectedMedia.url}
                      alt={selectedMedia.filedata.name}
                      style={{
                        width: 200,
                        boxShadow: "3px 3px 10px 3px rgba(0,0,0,0.2)",
                        borderRadius: "5px",
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      playing={false}
                      muted={true}
                      controls
                      width="230px"
                      height="130px"
                      url={selectedMedia.url}
                      style={{
                        boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.2)",
                      }}
                    />
                  )}
                </div>
              </div>

              <Tabs defaultActiveKey="1">
                <TabPane key="1" tab="Devices">
                  <Space direction="vertical">
                    {!_.isEmpty(selectedLocations) && (
                      <div align="center">
                        <Row align="center" gutter={[6, 6]}>
                          {selectedLocations.map((_locations, i) => (
                            <Col
                              key={i}
                              onClick={() => {
                                !_.isEmpty(
                                  _locations.media.filter(
                                    ({ url }) => url === selectedMedia.url
                                  )
                                )
                                  ? assignAndRemoveMedia("delete", _locations)
                                  : assignAndRemoveMedia("add", _locations);
                              }}
                            >
                              <motion.div
                                whileHover={{
                                  scale: 1.03,
                                  zIndex: 1,
                                }}
                              >
                                <Card
                                  hoverable
                                  style={{
                                    width: 200,
                                    backgroundColor: !_.isEmpty(
                                      _locations.media.filter(
                                        ({ url }) => url === selectedMedia.url
                                      )
                                    )
                                      ? "#69c0ff"
                                      : "#ccc",
                                    transition: "all 0.3s ease",
                                  }}
                                >
                                  {updating ? (
                                    <Skeleton.Input
                                      style={{ width: 150 }}
                                      active={true}
                                      size={20}
                                    />
                                  ) : (
                                    <Text
                                      ellipsis
                                      type={
                                        _.isEmpty(
                                          _locations.media.filter(
                                            ({ url }) =>
                                              url === selectedMedia.url
                                          )
                                        ) && "secondary"
                                      }
                                      strong={
                                        !_.isEmpty(
                                          _locations.media.filter(
                                            ({ url }) =>
                                              url === selectedMedia.url
                                          )
                                        ) && true
                                      }
                                      style={{
                                        color:
                                          !_.isEmpty(
                                            _locations.media.filter(
                                              ({ url }) =>
                                                url === selectedMedia.url
                                            )
                                          ) && "#fff",
                                      }}
                                    >
                                      {_locations.name} {" - "}
                                      {_locations.position.toUpperCase()}
                                    </Text>
                                  )}
                                </Card>
                              </motion.div>
                            </Col>
                          ))}
                        </Row>
                      </div>
                    )}
                    <br />
                  </Space>
                </TabPane>

                <TabPane key="2" tab="Auto destroy">
                  <Form
                    form={form}
                    initialValues={{
                      destroy:
                        selectedMedia.destroy && moment(selectedMedia.destroy),
                      willBeDestroyed: selectedMedia.destroy ? true : false,
                    }}
                    onFinish={handleDestroy}
                  >
                    <Space>
                      <Item
                        label="Auto destroy"
                        name="willBeDestroyed"
                        valuePropName="checked"
                      >
                        <Switch />
                      </Item>

                      <Item>
                        <Button
                          size="large"
                          type="link"
                          htmlType="submit"
                          loading={updating}
                          icon={<SaveFilled />}
                        >
                          Save
                        </Button>
                      </Item>
                    </Space>
                    <Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.willBeDestroyed !==
                        currentValues.willBeDestroyed
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("willBeDestroyed") && (
                          <Item name="destroy" label={"Date"}>
                            <DatePicker
                              format="DD/MM/YYYY"
                              disabledDate={(currentDate) =>
                                currentDate.isBefore(
                                  moment().subtract(1, "days")
                                )
                              }
                            />
                          </Item>
                        )
                      }
                    </Item>
                  </Form>
                </TabPane>
              </Tabs>
            </Modal>
          )}

          <Drawer
            closable
            width={400}
            style={{ borderRadius: "0px" }}
            onClose={() => setEditLocationModal(false)}
            visible={editLocationModal}
            headerStyle={{ backgroundColor: "#69c0ff" }}
            title={
              editingLocation && (
                <div align="center">
                  <Title level={4} style={{ color: "#FFF" }}>
                    {`${
                      editingLocation.name
                    } - ${editingLocation.position.toUpperCase()}`}
                  </Title>
                </div>
              )
            }
          >
            {editingLocation && (
              <>
                <Form hideRequiredMark form={form} onFinish={onFinish}>
                  <Space direction="vertical">
                    <Divider orientation="left">
                      <Text strong>Device settings</Text>
                    </Divider>
                    <Item
                      name="refreshRate"
                      label={
                        <Space>
                          <Popover
                            content={
                              <Text>
                                Please choose a range between 10 and 600 seconds
                              </Text>
                            }
                            title={<Text strong>Time in seconds</Text>}
                          >
                            <Button
                              size="small"
                              icon={<InfoCircleOutlined />}
                              type="link"
                            />
                          </Popover>
                          <span>Refresh rate</span>
                        </Space>
                      }
                      rules={[
                        {
                          required: true,
                          message: "Please input refresh rate",
                        },
                        {
                          type: "number",
                          min: 10,
                          max: 600,
                          message:
                            "Please choose a range between 10 and 600 seconds",
                        },
                      ]}
                    >
                      <InputNumber min={10} max={600} />
                    </Item>

                    <Item
                      valuePropName="checked"
                      name="showBanner"
                      label="Show banner"
                      rules={[{ required: true }]}
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </Item>

                    <Item
                      noStyle
                      shouldUpdate={(prevValues, currentValues) =>
                        prevValues.showBanner !== currentValues.showBanner
                      }
                    >
                      {({ getFieldValue }) =>
                        getFieldValue("showBanner") === true ? (
                          <>
                            <Item
                              name="bannerSpeed"
                              label="Speed"
                              rules={[{ required: true }]}
                            >
                              <Slider />
                            </Item>
                            <Item
                              name="bannerFontSize"
                              label="Font size"
                              rules={[{ required: true }]}
                            >
                              <Slider />
                            </Item>

                            <Item
                              name="bannerPosition"
                              label="Position"
                              rules={[
                                {
                                  required: true,
                                  message: "Please choose a banner position",
                                },
                              ]}
                            >
                              <Radio.Group>
                                <Radio.Button value="top">Top</Radio.Button>
                                <Radio.Button value="bottom">
                                  Bottom
                                </Radio.Button>
                              </Radio.Group>
                            </Item>
                            <Item
                              name="bannerType"
                              label="Banner type"
                              rules={[
                                {
                                  required: true,
                                  message: "Please choose a banner type",
                                },
                              ]}
                            >
                              <Radio.Group>
                                <Radio.Button value="loop">
                                  Vertical
                                </Radio.Button>
                                <Radio.Button value="marquee">
                                  Horizontal
                                </Radio.Button>
                              </Radio.Group>
                            </Item>

                            <Item
                              noStyle
                              shouldUpdate={(prevValues, currentValues) =>
                                prevValues.bannerType !==
                                currentValues.bannerType
                              }
                            >
                              {({ getFieldValue }) =>
                                getFieldValue("bannerType") === "marquee" ? (
                                  <>
                                    <Item
                                      name="bannerDescription"
                                      label="Description"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please type a description",
                                        },
                                      ]}
                                    >
                                      <Input.TextArea
                                        placeholder="Please Type a description"
                                        rows={4}
                                        showCount
                                      />
                                    </Item>
                                  </>
                                ) : (
                                  <>
                                    <List name="bannerLoopText">
                                      {(fields, { add, remove }) => (
                                        <>
                                          {fields.map(
                                            ({
                                              key,
                                              name,
                                              fieldKey,
                                              ...restField
                                            }) => (
                                              <motion.div
                                                key={key}
                                                initial={{ y: 20, opacity: 0 }}
                                                animate={{ y: 0, opacity: 1 }}
                                                transition={{
                                                  type: "tween",
                                                }}
                                              >
                                                <Row
                                                  gutter={[12, 12]}
                                                  justify="center"
                                                  key={key}
                                                >
                                                  <Col span={20}>
                                                    <Item
                                                      {...restField}
                                                      name={[
                                                        name,
                                                        "description",
                                                      ]}
                                                      fieldKey={[
                                                        fieldKey,
                                                        "description",
                                                      ]}
                                                      rules={[
                                                        {
                                                          required: true,
                                                          message:
                                                            "Missing description",
                                                        },
                                                      ]}
                                                    >
                                                      <Input placeholder="Description" />
                                                    </Item>
                                                  </Col>
                                                  <Col span={4}>
                                                    <Button
                                                      block
                                                      onClick={() =>
                                                        remove(name)
                                                      }
                                                      type="danger"
                                                      icon={
                                                        <DeleteOutlined
                                                          style={{
                                                            fontSize: 18,
                                                          }}
                                                        />
                                                      }
                                                    />
                                                  </Col>
                                                </Row>
                                              </motion.div>
                                            )
                                          )}
                                          <Item>
                                            <Button
                                              block
                                              type="dashed"
                                              onClick={() => add()}
                                              icon={<PlusOutlined />}
                                            >
                                              Add description
                                            </Button>
                                          </Item>
                                        </>
                                      )}
                                    </List>
                                  </>
                                )
                              }
                            </Item>

                            <Item
                              label="Banner background color"
                              valuePropName="color"
                              name="bgColor"
                            >
                              <CompactPicker />
                            </Item>
                            <Item
                              label="Banner text color"
                              valuePropName="color"
                              name="textColor"
                            >
                              <CompactPicker />
                            </Item>
                          </>
                        ) : null
                      }
                    </Item>

                    <Item
                      valuePropName="checked"
                      name="fade"
                      label="Fade effect"
                      rules={[{ required: true }]}
                    >
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </Item>
                    <Item>
                      <FakeCarousel />
                    </Item>
                  </Space>

                  <Item>
                    <div align="right">
                      <Space>
                        <Button onClick={() => setEditLocationModal(false)}>
                          Cancel
                        </Button>
                        <Button htmlType="submit" type="primary">
                          Submit
                        </Button>
                      </Space>
                    </div>
                  </Item>
                </Form>
              </>
            )}
          </Drawer>

          <>
            <br />

            <div align="center" className="divider">
              <Space direction="vertical">
                <Title level={3}>Working with</Title>

                <Select
                  defaultValue={
                    currentGateway ? currentGateway : userProps.gateway[0]
                  }
                  size="large"
                  style={{ width: "100%" }}
                  bordered={false}
                  showSearch
                  onChange={changeCurrentGateway}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {fillSelect.map((item, i) => (
                    <Option key={i} value={item.gateway}>
                      {`${item.gateway} - ${item.country}`}
                    </Option>
                  ))}
                </Select>
              </Space>
            </div>
            <Tabs
              style={{ paddingTop: 100 }}
              defaultActiveKey="1"
              size="large"
              centered
              onChange={handleTabChange}
            >
              <TabPane
                tab={
                  <span>
                    <FileImageOutlined />
                    Media Library
                  </span>
                }
                key="1"
              >
                <Divider />
                <Row gutter={[48, 48]} justify="center">
                  {data &&
                    !_.isEmpty(media) &&
                    media.map((item, key) => (
                      <Col key={key}>
                        <motion.div
                          initial={{ opacity: 0, y: 50 }}
                          animate={{ opacity: 1, y: 1 }}
                          transition={{ delay: key * 0.1 }}
                        >
                          <Card
                            bordered={false}
                            style={{
                              width: 250,
                              height: 250,
                            }}
                            headStyle={{
                              backgroundColor: "#69c0ff",
                            }}
                            title={
                              <Tooltip title={item.filedata.name}>
                                <Text style={{ color: "#fff" }} ellipsis>
                                  {item.filedata.name}
                                </Text>
                              </Tooltip>
                            }
                            extra={
                              item.filedata.type.includes("image") ? (
                                <FileImageTwoTone
                                  style={{ color: "#fff", fontSize: "25px" }}
                                />
                              ) : (
                                <VideoCameraTwoTone
                                  style={{ color: "#fff", fontSize: "25px" }}
                                />
                              )
                            }
                            actions={[
                              <Popconfirm
                                title="Are you sure to delete this item?"
                                onConfirm={() => handleDeleteMedia(item)}
                              >
                                <Tooltip placement="bottom" title="Delete">
                                  <DeleteOutlined style={{ color: "#fff" }} />
                                </Tooltip>
                              </Popconfirm>,

                              <Popover
                                content={
                                  <div style={{ minHeight: 200 }}>
                                    <div
                                      align="center"
                                      style={{ paddingTop: 40 }}
                                    >
                                      <Avatar
                                        style={{
                                          boxShadow:
                                            "3px 3px 10px 3px rgba(0,0,0,0.2)",
                                          position: "absolute",
                                          left: "38%",
                                          right: "0%",
                                          top: -30,
                                        }}
                                        src={item.filedata.currentUser.photoURL}
                                        size={64}
                                      />
                                      <Text code strong type="secondary">
                                        UPLOADED BY
                                      </Text>
                                    </div>
                                    <br />
                                    <Space direction="vertical" size={"small"}>
                                      <div>
                                        <Text strong type="secondary">
                                          Name:{" "}
                                        </Text>
                                        <Text strong>
                                          {
                                            item.filedata.currentUser
                                              .displayName
                                          }
                                        </Text>
                                      </div>

                                      <div>
                                        <Text strong type="secondary">
                                          Email:{" "}
                                        </Text>
                                        <Text strong>
                                          {item.filedata.currentUser.email}
                                        </Text>
                                      </div>

                                      <div>
                                        <Text strong type="secondary">
                                          Date Created:{" "}
                                        </Text>
                                        <Text strong>
                                          {" "}
                                          {moment(item.date).format("LL")}
                                        </Text>
                                      </div>

                                      <div>
                                        <Text strong type="secondary">
                                          File Size:{" "}
                                        </Text>
                                        <Text strong>
                                          {bytesToSize(item.filedata.size)}
                                        </Text>
                                      </div>
                                    </Space>
                                  </div>
                                }
                                // title="Title"
                                trigger="click"
                              >
                                <Tooltip placement="bottom" title="More info">
                                  <InfoCircleOutlined
                                    style={{ color: "#fff" }}
                                  />
                                </Tooltip>
                              </Popover>,

                              <Tooltip placement="bottom" title="Edit">
                                <EditOutlined
                                  style={{ color: "#fff" }}
                                  onClick={() => handleEditMedia(item)}
                                />
                              </Tooltip>,

                              <Tooltip
                                placement="bottom"
                                title={
                                  item.destroy
                                    ? `Will be deleted on ${moment(
                                        item.destroy
                                      ).format("LL")}`
                                    : "Auto destroy disabled"
                                }
                              >
                                <ClockCircleOutlined
                                  style={{
                                    color: item.destroy ? "#fff" : "#ccc",
                                  }}
                                />
                              </Tooltip>,
                            ]}
                          >
                            {loading ? (
                              <div align="center">
                                <Skeleton.Avatar
                                  shape="square"
                                  size={126}
                                  style={{
                                    width: 200,
                                    boxShadow:
                                      "3px 3px 10px 3px rgba(0,0,0,0.2)",
                                    borderRadius: "2px",
                                  }}
                                  active
                                  loading={loading}
                                />
                              </div>
                            ) : item.filedata.type.includes("image") ? (
                              <Image
                                alt={item.filedata.name}
                                src={item.url}
                                width={"100%"}
                                height={120}
                                fallback={
                                  "https://firebasestorage.googleapis.com/v0/b/digital-signage-b21f0.appspot.com/o/fallback.png?alt=media&token=92a43656-e14e-489e-a14e-6d6eed4680d5"
                                }
                                style={{
                                  boxShadow: "3px 3px 10px 3px rgba(0,0,0,0.2)",
                                  borderRadius: "2px",
                                }}
                              />
                            ) : (
                              <ReactPlayer
                                playing={false}
                                muted={true}
                                playIcon={false}
                                light={
                                  "https://firebasestorage.googleapis.com/v0/b/digital-signage-b21f0.appspot.com/o/isVideo.gif?alt=media&token=3003a626-2284-497b-9b06-79f6190d289a"
                                }
                                controls
                                width="100%"
                                height="126px"
                                url={item.url}
                                style={{
                                  boxShadow: "1px 1px 10px 1px rgba(0,0,0,0.2)",
                                }}
                              />
                            )}
                          </Card>
                        </motion.div>
                      </Col>
                    ))}
                </Row>
              </TabPane>

              <TabPane
                tab={
                  <span>
                    <PlaySquareOutlined />
                    Live Content
                  </span>
                }
                key="2"
              >
                <Divider />

                {data && (
                  <Row gutter={[48, 48]} justify="center">
                    {data.map((item) =>
                      item.gateway === currentGateway
                        ? item.locations.map((loc, key) => (
                            <Col key={key}>
                              <Card
                                title={
                                  loc.name + " - " + loc.position.toUpperCase()
                                }
                                hoverable
                                bordered={false}
                                style={{ width: 250 }}
                                headStyle={{
                                  backgroundColor: "#69c0ff",
                                }}
                                extra={
                                  <Tooltip title={`Device ID: ${loc.id}`}>
                                    <InfoCircleOutlined
                                      style={{ color: "#fff" }}
                                    />
                                  </Tooltip>
                                }
                                actions={[
                                  <Tooltip
                                    placement="bottom"
                                    title="Open link in a new tab"
                                  >
                                    <LinkOutlined
                                      style={{ color: "#fff" }}
                                      onClick={() =>
                                        // history.replace({
                                        //   pathname: "/display",
                                        //   search: `?id=${loc.id}`,
                                        //   state: {
                                        //     ...loc,
                                        //     gateway: currentGateway,
                                        //   },
                                        // })
                                        window.open(
                                          `http://${window.location.host}/display?id=${loc.id}`
                                        )
                                      }
                                    />
                                  </Tooltip>,
                                  _.isEmpty(loc.media) ? (
                                    <Tooltip
                                      placement="bottom"
                                      title="Remove all media"
                                    >
                                      <ClearOutlined
                                        style={{ color: "#808080" }}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <Popconfirm
                                      title="Do you want to clear all media?"
                                      onConfirm={() => clearAllMedia(loc)}
                                    >
                                      <Tooltip
                                        placement="bottom"
                                        title="Remove all media"
                                      >
                                        <ClearOutlined
                                          style={{ color: "#fff" }}
                                        />
                                      </Tooltip>
                                    </Popconfirm>
                                  ),

                                  <Tooltip placement="bottom" title="Settings">
                                    <SettingOutlined
                                      style={{ color: "#fff" }}
                                      onClick={() => handleEditLocation(loc)}
                                    />
                                  </Tooltip>,
                                ]}
                              >
                                {updating ? (
                                  <div align="center">
                                    <Skeleton.Avatar
                                      shape="square"
                                      style={{ width: 200 }}
                                      size={120}
                                      active
                                      loading={updating}
                                    />
                                  </div>
                                ) : (
                                  <>
                                    <div
                                      style={{
                                        position: "absolute",
                                        zIndex: 1,
                                        left: 24,
                                        right: 24,
                                        top:
                                          loc.bannerPosition === "top"
                                            ? "60px"
                                            : "auto",
                                        bottom:
                                          loc.bannerPosition === "bottom"
                                            ? "50px"
                                            : "auto",
                                      }}
                                    >
                                      {loc.showBanner &&
                                        (loc.bannerType === "marquee" ? (
                                          <Alert
                                            type="info"
                                            banner
                                            showIcon={false}
                                            style={{
                                              backgroundColor: loc.bgColor.hex,
                                            }}
                                            message={
                                              <Marquee
                                                speed={loc.bannerSpeed}
                                                gradient={false}
                                                style={{
                                                  fontSize: 10,
                                                  color: loc.textColor.hex,
                                                }}
                                              >
                                                {loc.bannerDescription}
                                              </Marquee>
                                            }
                                          />
                                        ) : loc.bannerType === "loop" ? (
                                          <Alert
                                            type="info"
                                            showIcon={false}
                                            banner
                                            style={{
                                              backgroundColor: loc.bgColor.hex,
                                            }}
                                            message={
                                              <TextLoop
                                                mask
                                                adjustingSpeed={loc.bannerSpeed}
                                              >
                                                {loc.bannerLoopText.map(
                                                  (text, i) => (
                                                    <div
                                                      key={i}
                                                      style={{
                                                        fontSize: 10,
                                                        color:
                                                          loc.textColor.hex,
                                                      }}
                                                    >
                                                      {text.description}
                                                    </div>
                                                  )
                                                )}
                                              </TextLoop>
                                            }
                                          />
                                        ) : null)}
                                    </div>

                                    <Carousel
                                      autoplay
                                      pauseOnHover={false}
                                      autoplaySpeed={1000}
                                    >
                                      {_.isEmpty(loc.media) && (
                                        <div>
                                          <div
                                            style={{
                                              backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/digital-signage-b21f0.appspot.com/o/video.gif?alt=media&token=7c7a97a2-b09a-4fe3-9af8-a7baed06401a)`,
                                              backgroundRepeat: "no-repeat",
                                              height: "120px",
                                              backgroundSize: "cover",
                                              backgroundPosition: "center",
                                              lineHeight: "30px",
                                              textAlign: "center",
                                            }}
                                          >
                                            <Text
                                              code
                                              style={{ fontSize: 20 }}
                                              type="warning"
                                            >
                                              Empty
                                            </Text>
                                          </div>
                                        </div>
                                      )}

                                      {loc.media.map((item, i) => (
                                        <div key={i}>
                                          {item.type.includes("video") ? (
                                            <ReactPlayer
                                              playing={true}
                                              muted={true}
                                              loop
                                              controls
                                              width="100%"
                                              height="120px"
                                              url={item.url}
                                            />
                                          ) : (
                                            <div
                                              style={{
                                                backgroundImage: `url(${item.url})`,
                                                backgroundRepeat: "no-repeat",
                                                height: "120px",
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                              }}
                                            />
                                          )}
                                        </div>
                                      ))}
                                    </Carousel>
                                  </>
                                )}
                              </Card>
                            </Col>
                          ))
                        : null
                    )}
                  </Row>
                )}
              </TabPane>

              <TabPane
                tab={
                  <span>
                    <UploadOutlined />
                    Upload Media
                  </span>
                }
                key="3"
              >
                <Row>
                  <Col span={24}>
                    <Divider />
                    <UploadPhoto
                      gateway={currentGateway || userProps.gateway[0]}
                    />
                  </Col>
                </Row>
              </TabPane>
            </Tabs>
          </>
        </>
      )}
    </>
  );
}
