import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod } from "../misc/CRUD";

import HeaderMenu from "./HeaderMenu";
import Body from "./Body";
import { motion } from "framer-motion";
import { Copyright, Spinner } from "../misc/Generic";
import WaitingAccess from "../login/WaitingAccess";

const { Content, Footer } = Layout;

export default function Dashboard() {
  const { currentUser, API } = useGlobal();
  const [status, setStatus] = useState("");

  useEffect(() => {
    let isCancelled = false;
    GetMethod(`${API}users/${currentUser.email}`).then((res) => {
      if (!isCancelled) {
        res._id ? setStatus(res.status) : window.location.reload();
      }
    });
    return () => {
      isCancelled = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return status ? (
    status === "active" ? (
      <>
        <Layout style={{ minHeight: "100vh" }}>
          <HeaderMenu />
          <Content
            style={{
              // padding: "10px 50px 50px 50px 50px",
              // marginTop: 64,
              // maxWidth: "1440px",
              // padding: 10,
              // margin: 50,
              // minHeight: 280,
              margin: "1em auto",
              padding: 50,
              width: "150em",
              maxWidth: "calc(100% - 2em)",
              // padding: 24,
              // minHeight: 280,
            }}
          >
            <Body />
          </Content>
          <Footer style={{ textAlign: "center", paddingTop: "10vh" }}>
            <motion.div
              initial={{ y: 15, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 1,
                type: "spring",
                stiffness: 120,
              }}
            >
              <Copyright />
            </motion.div>
          </Footer>
        </Layout>
      </>
    ) : (
      <WaitingAccess />
    )
  ) : (
    <Spinner />
  );
}
