import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";
import { useGlobal } from "../context/GlobalContext";
import { GetMethod, PatchMethod } from "../misc/CRUD";
import { CustomHeader, Motion, Spinner } from "../misc/Generic";
import { Notification } from "../misc/Generic";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import LoadingBar from "react-top-loading-bar";
import { PlusOutlined, DeleteOutlined, AimOutlined } from "@ant-design/icons";
import {
  Skeleton,
  Select,
  Modal,
  Space,
  Button,
  Form,
  Col,
  Row,
  Input,
  Tooltip,
  Card,
  Divider,
  Drawer,
  Typography,
  Switch,
  Collapse,
  Alert,
} from "antd";

const { Item, List } = Form;
const { Text, Title } = Typography;
const { Panel } = Collapse;
const { Option } = Select;

export default function Gateways() {
  const { API } = useGlobal();
  const [data, setData] = useState([]);
  const [send, setSend] = useState(false);
  const [error, setError] = useState("");
  const [showDrawer, setShowDrawer] = useState(false);
  const [editData, setEditData] = useState("");
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [form] = Form.useForm();
  const ref = useRef();

  useEffect(() => {
    let isMounted = true;
    ref.current.continuousStart();

    if (isMounted) {
      GetMethod(`${API}gateways/`)
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setShowSkeleton(false);
          try {
            ref.current.complete();
          } catch (error) {}
        });
    }

    return () => {
      isMounted = false;
    };
  }, [API]);

  const handleEdit = (selectedCard) => {
    setEditData(selectedCard);
    form.setFieldsValue({
      gateway: selectedCard.gateway,
      country: selectedCard.country,
      enabled: selectedCard.enabled,
      locations: selectedCard.locations,
    });
    setShowDrawer(true);
  };

  const handleCloseDrawer = () => {
    setShowDrawer(false);
  };

  const onFinish = (values) => {
    if (_.isEmpty(values.locations) && values.enabled) {
      setSend(false);
      Notification("topRight", "error", "You must create at least one device");
    } else {
      ref.current.continuousStart();
      setSend(true);

      values.locations.forEach((item) => {
        if (!item.id) {
          item.id = uuidv4().substring(1, 8);
        }

        if (!item.refreshRate) {
          item.refreshRate = 20;
        }

        if (!item.fade) {
          item.fade = true;
        }

        if (!item.showBanner) {
          item.showBanner = false;
          item.bannerType = "marquee";
          item.bannerSpeed = 30;
          item.bannerPosition = "bottom";
          item.bannerFontSize = "12";
          item.bannerDescription = "";
          item.bgColor = "#000";
          item.textColor = "#fff";
          item.bannerLoopText = [];
        }
      });

      PatchMethod(`${API}gateways/${editData._id}`, values)
        .then((res) => {
          setSend(false);
          setData(res);
          Notification("bottomLeft", "success", "Gateway updated successfully");
        })
        .catch((err) => {
          setError(err);
          setSend(false);
        })
        .finally(() => {
          ref.current.complete();
        });
    }
  };

  const ShowAlert = () => {
    return (
      <>
        <Divider />
        <Alert
          message="Error"
          showIcon
          description={error.message}
          type="error"
        />
      </>
    );
  };

  return (
    <>
      <LoadingBar ref={ref} color="#1890ff" height={3} shadow={false} />

      {error && <ShowAlert />}

      <>
        <CustomHeader label="Gateway Management" />
        {!_.isEmpty(data) ? (
          <>
            <Row align="center" gutter={[6, 6]}>
              {data.map((item, i) => (
                <Col key={i} onClick={() => handleEdit(item)}>
                  <motion.div
                    whileHover={{
                      scale: 1.03,
                      zIndex: 1,
                    }}
                  >
                    <motion.div
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 1 }}
                      transition={{ delay: i * 0.008 }}
                    >
                      <Tooltip
                        title={
                          item.locations &&
                          `Locations: ${item.locations.length}`
                        }
                      >
                        <Card
                          hoverable
                          style={{
                            background: item.enabled
                              ? "#69c0ff"
                              : !item.enabled && !_.isEmpty(item.locations)
                              ? "#ccc"
                              : "#bae7ff",
                            textAlign: "center",
                            borderRadius: "5px",
                            width: 300,
                            zIndex: 1,
                          }}
                        >
                          {showSkeleton ? (
                            <Skeleton.Input
                              style={{ width: 200 }}
                              active={true}
                              size="small"
                            />
                          ) : (
                            <Text
                              ellipsis
                              type={!item.enabled && "secondary"}
                              strong={item.enabled && true}
                              style={{ color: item.enabled && "#fff" }}
                            >
                              {item.gateway} - {item.country}
                            </Text>
                          )}
                        </Card>
                      </Tooltip>
                    </motion.div>
                  </motion.div>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <Spinner />
        )}

        <Drawer
          width={"50%"}
          title="Edit Gateway"
          onClose={handleCloseDrawer}
          visible={showDrawer}
        >
          <>
            <Form form={form} requiredMark={false} onFinish={onFinish}>
              <Row gutter={[24, 24]} align="middle">
                <Col span={10}>
                  <Item
                    label="Gatway"
                    name="gateway"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input disabled placeholder="Costa Rica" />
                  </Item>
                </Col>
                <Col span={10}>
                  <Item
                    label="Country"
                    name="country"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input disabled placeholder="SJO" />
                  </Item>
                </Col>
                <Col span={4}>
                  <Item
                    valuePropName="checked"
                    name="enabled"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Switch
                      checkedChildren="Enabled"
                      unCheckedChildren="Disabled"
                    />
                  </Item>
                </Col>
              </Row>

              <Divider>
                <Title level={3}>Devices</Title>
              </Divider>

              <List name="locations">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                      <Row gutter={[12, 12]} key={key}>
                        <Col span={11}>
                          <Item
                            {...restField}
                            name={[name, "name"]}
                            fieldKey={[fieldKey, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing location",
                              },
                            ]}
                          >
                            <Input
                              addonBefore={<AimOutlined />}
                              placeholder="Location"
                            />
                          </Item>
                        </Col>
                        <Col span={11}>
                          <Item
                            {...restField}
                            name={[name, "position"]}
                            fieldKey={[fieldKey, "position"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing position",
                              },
                            ]}
                          >
                            <Select placeholder="Position">
                              <Option value="right">Rigth</Option>
                              <Option value="left">Left</Option>
                              <Option value="other">Other</Option>
                            </Select>
                          </Item>
                        </Col>

                        <Col span={2}>
                          <Button
                            block
                            onClick={() =>
                              Modal.confirm({
                                title: "Please confirm",
                                width: "600px",
                                content: (
                                  <Alert
                                    message="Are you sure to delete this item?"
                                    description="This action cannot be undone. You can also recreate it, but the URL will be different."
                                    type="warning"
                                  />
                                ),

                                onOk() {
                                  remove(name);
                                },
                              })
                            }
                            type="danger"
                            icon={<DeleteOutlined style={{ fontSize: 18 }} />}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        New device
                      </Button>
                    </Item>
                  </>
                )}
              </List>

              <div align="center">
                <Item>
                  <br />
                  <Space size={10}>
                    <Button htmlType="button" onClick={handleCloseDrawer}>
                      Cancel
                    </Button>
                    <Button loading={send} type="primary" htmlType="submit">
                      Submit
                    </Button>
                  </Space>
                </Item>
              </div>
            </Form>
          </>
        </Drawer>
      </>
    </>
  );
}
