import React from "react";
import { useGlobal } from "../context/GlobalContext";
import { Button, Typography, Card, Divider, Avatar, Space } from "antd";
import { Copyright } from "../misc/Generic";
import aeropostLogo from "../../images/aeropost-dark-blue.png";
import { motion } from "framer-motion";
import { LogoutOutlined, ReloadOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

export default function WaitingAcess() {
  const { logout, currentUser } = useGlobal();

  return (
    <motion.div
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 20, opacity: 1 }}
      transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
    >
      <div style={{ paddingTop: 17 }}>
        <div align="center" style={{ background: "#9fc6e6" }}>
          <img src={aeropostLogo} alt="logo" width={250} />
        </div>
        <Divider orientation="center" />

        <div align="center" style={{ paddingTop: 40 }}>
          <Card
            style={{
              width: "80%",
              background: "#9fc6e6",
            }}
            align="center"
          >
            <div
              align="center"
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: "-15%",
              }}
            >
              {currentUser.photoURL ? (
                <>
                  <Avatar
                    style={{
                      background: "#FFF",
                    }}
                    size={80}
                  >
                    <Avatar size={75} src={currentUser.photoURL} />
                  </Avatar>
                </>
              ) : null}
            </div>
            <br />
            <Title level={3}>
              <Text>
                {`Hi ${currentUser.displayName}, welcome to Aeropost Digital Signage application!`}
              </Text>
            </Title>
            <Divider dashed />
            <Text>
              Please wait while an administrator assigns your permissions
            </Text>
            <br />
            <br />
            <br />
            <Divider dashed />

            <Space size="large">
              <Button
                icon={<ReloadOutlined />}
                size="large"
                type="ghost"
                onClick={() => window.location.reload()}
              >
                Refresh
              </Button>
              <Button
                icon={<LogoutOutlined />}
                size="large"
                type="primary"
                onClick={() => logout()}
              >
                Exit
              </Button>
            </Space>
          </Card>

          <div align="center" style={{ paddingTop: 40 }}>
            <Copyright />
          </div>
        </div>
      </div>
    </motion.div>
  );
}
