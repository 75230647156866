import React from "react";
import { useGlobal } from "../context/GlobalContext";
import { BrowserRouter as Switch, Route, Redirect } from "react-router-dom";
import Dashboard from "../layout/Dashboard";
import Login from "../login/MainPage";

export default function Landing() {
  const { currentUser } = useGlobal();
  return (
    <Switch>
      {currentUser ? <Dashboard /> : <Redirect to="/login" />}
      <Route exact path="/login" component={Login} />
    </Switch>
  );
}
