import React, { useState, useEffect, useRef } from "react";
import { PostMethod, DeleteMethod } from "./CRUD";
import { useGlobal } from "../context/GlobalContext";
import LoadingBar from "react-top-loading-bar";
import ReactPlayer from "react-player";
import { storage } from "../context/firebase";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import {
  ClearOutlined,
  CloudUploadOutlined,
  FileImageOutlined,
  VideoCameraOutlined,
  FileOutlined,
  StopOutlined,
} from "@ant-design/icons";
import {
  Card,
  Upload,
  Space,
  Button,
  message,
  Typography,
  Badge,
  Divider,
  Modal,
  Switch,
  Tooltip,
} from "antd";

const { Text, Title } = Typography;
const { Dragger } = Upload;

export function UploadPhoto({ gateway }) {
  const { API, currentUser } = useGlobal();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [changeView, setChangeView] = useState("picture-card");
  const [fileList, setFileList] = useState([]);
  const [previewFile, setPreviewFile] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const ref = useRef(null);

  useEffect(() => {
    localStorage.clear();
  }, []);

  const onChange = ({ fileList: newFileList }) => {
    let arr = [];
    let elementWithErr;

    newFileList.forEach((element) => {
      if (!element.status) {
        elementWithErr = { ...element, status: "error" };
        arr.push(elementWithErr);
      } else {
        arr.push(element);
        localStorage.setItem("_files", JSON.stringify(arr));
      }
    });

    setFileList(arr);
  };

  const findIcon = (file) => {
    return file.type.includes("image") ? (
      <FileImageOutlined />
    ) : file.type === "video/mp4" ? (
      <VideoCameraOutlined key={uuidv4()} />
    ) : file.status === "error" ? (
      <StopOutlined />
    ) : (
      <FileOutlined key={uuidv4()} />
    );
  };

  const draggerProps = {
    name: "file",
    showUploadList: true,

    iconRender: (file) => findIcon(file),
    customRequest: async ({ onSuccess, onError, file }) => {
      const uploadTask = storage
        .ref(`gateways/${gateway}/${file.name}`)
        .put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          ref.current.continuousStart();
        },
        (error) => {},
        (item) => {
          storage
            .ref(`gateways/${gateway}`)
            .child(file.name)
            .getDownloadURL()
            .then((url) => {
              const { type, name, size, uid } = file;
              const values = {
                url,
                gateway,
                uid,
                filedata: {
                  type,
                  name,
                  size,
                  currentUser,
                },
              };
              PostMethod(`${API}media/`, values)
                .then(() => {
                  onSuccess(url);
                })
                .catch((e) => {
                  onError(e);
                })
                .finally(() => {
                  ref.current.complete();
                });
            })
            .catch((e) => {
              onError(e);
            });
        }
      );
    },
  };

  function beforeUpload(file) {
    const defaultSize = file.size < 262144000; //250mb

    if (defaultSize) {
      return true;
    } else {
      // Notification("bottomRight", "error", "Image must smaller than 250MB!");
      message.error("Media must be smaller than 250MB", 5);
      return false;
    }
  }

  const onPreview = async (file) => {
    if (!file.status.includes("error")) {
      let src = file.url;
      if (!src) {
        src = await new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsDataURL(file.originFileObj);
          reader.onload = () => resolve(reader.result);
        });
      }
      const image = new Image();
      image.src = src;
      setPreviewImage(image.src);
      setPreviewFile(file);
      setPreviewVisible(true);
    }
  };

  const onRemove = async (file) => {
    if (file.status === "error") {
      message.success("File deleted successfully");
    } else {
      ref.current.continuousStart();
      await storage
        .refFromURL(`${file.response}`)
        .delete()
        .then(async (res) => {
          try {
            await DeleteMethod(`${API}media/${file.uid}`);
          } catch (_) {}
        })
        .finally(() => {
          ref.current.complete();
        })
        .catch((e) => {});
    }
  };

  const handleCloseModal = () => {
    setPreviewVisible(false);
  };

  return (
    gateway && (
      <>
        <LoadingBar ref={ref} color="#1890ff" height={3} shadow={false} />

        <div align="center">
          <div style={{ paddingBottom: 5 }} />
          <Card
            headStyle={{ backgroundColor: "#69c0ff" }}
            title={
              <Title style={{ color: "#fff" }} level={4}>
                Media Uploader for {gateway}
              </Title>
            }
            extra={
              <Space>
                {fileList.length > 0 && (
                  <Button
                    onClick={() => setFileList([])}
                    icon={<ClearOutlined style={{ fontSize: 18 }} />}
                    type="primary"
                  >
                    Click here to clear
                  </Button>
                )}

                <Switch
                  disabled={_.isEmpty(fileList)}
                  defaultChecked
                  checkedChildren="Grid View"
                  unCheckedChildren="List View"
                  onChange={() => setChangeView(!changeView)}
                />
              </Space>
            }
            style={{ maxWidth: 800 }}
          >
            <Space>
              {fileList.length > 0 && <Text>Uploaded files</Text>}
              <Badge
                style={{
                  background: "#1890ff",
                }}
                count={fileList.length}
              />
            </Space>
            {fileList.length > 0 && <Divider />}

            <Dragger
              {...draggerProps}
              onChange={onChange}
              beforeUpload={beforeUpload}
              onPreview={onPreview}
              accept=".png, .jpg, .jpeg, .gif, .mp4"
              multiple
              onRemove={onRemove}
              fileList={fileList}
              listType={changeView ? "picture-card" : "picture"}
              style={{
                background: "#e6f7ff",
              }}
            >
              <CloudUploadOutlined style={{ fontSize: 50 }} />
              <Title level={5}>
                Drag and drop your files here or click to browse.
              </Title>
              <Divider dashed />
              <Space direction="vertical" size={6}>
                <Text type="secondary">
                  We support JPG, PNG, MP4 and JPEG file formats.
                </Text>
                <Text type="secondary">Max file size 250mb each.</Text>
              </Space>
            </Dragger>
          </Card>
        </div>

        <Modal
          width={800}
          visible={previewVisible}
          title={previewFile.name}
          footer={
            <Button type="link" onClick={handleCloseModal}>
              Close
            </Button>
          }
          onCancel={handleCloseModal}
        >
          <div align="center">
            {previewFile.type === "video/mp4" ? (
              <ReactPlayer
                playing
                muted={true}
                controls
                width="100%"
                url={previewFile.response}
              />
            ) : (
              <img
                alt="previewImage"
                style={{
                  width: "100%",
                  borderRadius: "7px",
                  boxShadow: "3px 3px 10px 3px rgba(0,0,0,0.2)",
                }}
                src={previewImage}
              />
            )}
          </div>
        </Modal>
      </>
    )
  );
}
