import React, { createContext, useContext, useState, useEffect } from "react";
import { auth } from "./firebase";
import { GetMethod, PostMethod } from "../misc/CRUD";
import firebase from "firebase/compat/app";

const GlobalContext = createContext();

export function useGlobal() {
  return useContext(GlobalContext);
}

export function GlobalProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userProps, setUserProps] = useState();
  const [photo, setPhoto] = useState();
  const [loading, setLoading] = useState(true);
  const API = "https://fpauow02ec.execute-api.us-west-2.amazonaws.com/dev/api/";
  // const API = "http://localhost:4000/api/";

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        GetMethod(`${API}users/${user.email}`).then((userProfile) => {
          setUserProps(userProfile);
        });
      }
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, [currentUser]);

  // function signup(email, password) {
  //   return auth.createUserWithEmailAndPassword(email, password).then((user) => {
  //     user.sendEmailVerification();
  //   });
  // }

  function loginWithGoogle() {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(async (result) => {
        let response = result.user;
        let aeropostUser = response.email.replace(/.*@/, "");

        if (aeropostUser !== "aeropost.com") {
          result.user.delete();
          return alert(
            "Wrong Google account, you must be part of the organization to log in"
          );
        } else {
          var firstName = response.displayName
            .split(" ")
            .slice(0, -1)
            .join(" ");
          var lastName = response.displayName.split(" ").slice(-1).join(" ");

          let _user = {
            email: response.email,
            firstName: firstName,
            lastName: lastName,
            uid: response.uid,
            role: "agent",
          };

          PostMethod(`${API}users/`, _user).then(() => {});
        }
      })
      .catch((_) => {});
  }

  function logout() {
    auth.signOut();
  }

  const value = {
    userProps,
    loginWithGoogle,
    photo,
    setPhoto,
    logout,
    currentUser,
    API,
  };

  return (
    <GlobalContext.Provider value={value}>
      {!loading && children}
    </GlobalContext.Provider>
  );
}
