import React, { useState, useEffect } from "react";
import { GetMethod } from "../misc/CRUD";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";

import { useGlobal } from "../context/GlobalContext";
import { Permissions } from "../misc/Generic";
import Login from "../login/MainPage";
import PrivateRoute from "../routes/PrivateRoutes";
import Users from "../views/Users";
import Gateways from "../views/Gateways";

import Home from "../views/Home";

export default function Body() {
  const [role, setRole] = useState();
  const { currentUser, API } = useGlobal();
  const location = useLocation();

  useEffect(() => {
    let mounted = false;

    GetMethod(`${API}users/${currentUser.email}`).then((res) => {
      if (!mounted) {
        setRole(res.role);
      }
    });
    return () => {
      mounted = true;
    };
  }, [API, currentUser.email]);

  return role ? (
    <>
      <Switch location={location} key={location.key}>
        <PrivateRoute exact path="/">
          <Redirect to="/home" />
        </PrivateRoute>

        <PrivateRoute
          path="/users"
          component={() =>
            Permissions("users").map(
              (item, i) => item === role && <Users key={i} />
            )
          }
        />

        <PrivateRoute
          path="/home"
          component={() =>
            Permissions("home").map(
              (item, i) => item === role && <Home key={i} />
            )
          }
        />

        <PrivateRoute
          path="/gateways"
          component={() =>
            Permissions("gateways").map(
              (item, i) => item === role && <Gateways key={i} />
            )
          }
        />

        <Route path="/login" component={Login} />
        <Route path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  ) : null;
}
