import React, { useState, useEffect } from "react";
import "./App.css";
import Landing from "../routes/Landing";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { useGlobal } from "../context/GlobalContext";
import axios from "axios";
import Display from "../views/Display";

export default function App() {
  const { API } = useGlobal();
  const [data, setData] = useState([]);
  const [displayFound, setDisplayFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const [_id] = useState(window.location.search.split("=")[1]);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);
    axios
      .get(`${API}gateways/${_id}`)
      .then((res) => {
        if (isMounted) {
          const _locations = res.data.locations;
          _locations.forEach((location) => {
            if (location.id === _id) {
              setDisplayFound(true);
              setData({ ...location, gateway: res.data.gateway });
            }
          });
        }
      })
      .catch((_) => {})
      .finally(() => {
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Router>
        <Route exact path="/display" component={Display} />
        {displayFound ? (
          <Redirect
            to={{
              pathname: "/display",
              search: `?id=${_id}`,
              state: data,
            }}
          />
        ) : (
          <Landing />
        )}
      </Router>
    </>
  );
}
