import React, { useState, useEffect } from "react";
import { useGlobal } from "../context/GlobalContext";
import { Link, useHistory } from "react-router-dom";
import { GetMethod } from "../misc/CRUD";
import { Permissions } from "../misc/Generic";
import Logo from "../../images/aeropost-dark-blue.png";
import { motion } from "framer-motion";
import {
  Divider,
  Layout,
  Menu,
  Space,
  Avatar,
  Row,
  Col,
  Button,
  Typography,
  Popover,
  Drawer,
} from "antd";
import {
  ControlOutlined,
  TeamOutlined,
  PoweroffOutlined,
  MenuOutlined,
  CloseOutlined,
  DashboardOutlined,
} from "@ant-design/icons";
const { Item } = Menu;
const { Header } = Layout;
const { Title, Text } = Typography;

export default function HeaderMenu() {
  const { API, logout, currentUser } = useGlobal();
  const [userProfile, setUserProfile] = useState();
  const [openDrawer, setOpenDrawer] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let isMounted = true;

    GetMethod(`${API}users/${currentUser.email}`).then((userProfile) => {
      if (isMounted) {
        setUserProfile(userProfile);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [API, currentUser.email]);

  const styles = {
    centerStyle: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
    rightStyle: { position: "absolute", top: 0, right: 30 },
    header: {
      background: "#fff",
      position: "fixed",
      zIndex: 2,
      width: "100%",
      boxShadow: "3px 3px 10px 3px rgba(0,0,0,0.2)",
    },
    drawer: { backdropFilter: "blur(2px)" },
    logo: {
      borderRadius: "10px",
      background: "#91d5ff",
      boxShadow: "3px 3px 10px 3px #ccc",
    },
    drawerHeader: {
      top: -12,
      background: "#91d5ff",
      boxShadow: "3px 3px 10px 3px #ccc",
    },
  };

  const exit = () => {
    logout();
    localStorage.clear();
    history.push("/");
  };

  const RenderMenu = ({ mode, showIcon }) => {
    return (
      <Menu
        motion={
          mode === "horizontal" ? { x: { stiffness: 1000, damping: 26 } } : {}
        }
        style={mode === "horizontal" ? styles.centerStyle : null}
        defaultSelectedKeys={[window.location.pathname]}
        mode={mode}
      >
        {Permissions("home").map(
          (item) =>
            item === userProfile.role && (
              <Item key={"/home"} icon={showIcon && <DashboardOutlined />}>
                <Link to="/home" /> Home
              </Item>
            )
        )}

        {Permissions("users").map(
          (item) =>
            item === userProfile.role && (
              <Item key={"/users"} icon={showIcon && <TeamOutlined />}>
                <Link to="/users" />
                Users
              </Item>
            )
        )}

        {Permissions("gateways").map(
          (item) =>
            item === userProfile.role && (
              <Item key={"/gateways"} icon={showIcon && <TeamOutlined />}>
                <Link to="/gateways" />
                Gateways
              </Item>
            )
        )}
      </Menu>
    );
  };

  const RenderAvatar = ({ displayName, email, photoURL }) => {
    return (
      <Space align="baseline">
        <Popover
          placement="bottomRight"
          trigger="hover"
          title={
            <div align="center">
              <Title level={5}>{displayName}</Title>
              <Text>{email}</Text>
            </div>
          }
          content={
            <div align="center" style={{ padding: 30 }}>
              <Button
                block
                danger
                shape="round"
                onClick={exit}
                icon={<PoweroffOutlined />}
              >
                Logout
              </Button>
            </div>
          }
        >
          {photoURL ? (
            <Avatar src={photoURL} />
          ) : (
            <Avatar
              style={{
                backgroundColor: "#8DC7E8",
                verticalAlign: "middle",
              }}
              size={32}
            >
              <span style={{ fontSize: 16 }}>
                {`${userProfile.firstName.substring(
                  0,
                  1
                )}${userProfile.lastName.substring(0, 1)}`}
              </span>
            </Avatar>
          )}
        </Popover>
      </Space>
    );
  };

  return (
    <>
      {userProfile ? (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
        >
          <Header style={styles.header}>
            <Row justify="center">
              <Col align="center" xs={{ span: 0 }} md={{ span: 6 }}>
                <Link to={"/home"}>
                  <img src={Logo} alt="logo" width={175} />
                </Link>
              </Col>

              <Col align="center" xs={{ span: 0 }} md={{ span: 12 }}>
                <RenderMenu mode="horizontal" />
              </Col>

              <Col align="center" xs={{ span: 0 }} md={{ span: 6 }}>
                <div style={styles.rightStyle}>
                  <div>
                    <RenderAvatar
                      displayName={currentUser.displayName}
                      email={currentUser.email}
                      photoURL={currentUser.photoURL}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row justify="center">
              <Col align="center" xs={{ span: 4 }} md={{ span: 0 }}>
                <Button
                  type="link"
                  onClick={() => setOpenDrawer(!openDrawer)}
                  icon={<MenuOutlined />}
                />
              </Col>

              <Col align="center" xs={{ span: 16 }} md={{ span: 0 }}>
                <img src={Logo} alt="logo" width={150} />
              </Col>

              <Col align="center" xs={{ span: 4 }} md={{ span: 0 }}>
                <RenderAvatar
                  displayName={currentUser.displayName}
                  email={currentUser.email}
                  photoURL={currentUser.photoURL}
                />
              </Col>
            </Row>
          </Header>
        </motion.div>
      ) : null}
      <Drawer
        closable={true}
        closeIcon={false}
        onClose={() => setOpenDrawer(false)}
        height={"60vh"}
        style={styles.drawer}
        placement="bottom"
        visible={openDrawer}
        headerStyle={styles.drawerHeader}
        footer={
          <div align="center" style={styles.logo}>
            <img src={Logo} alt="logo" width={150} />
          </div>
        }
      >
        <div align="center">
          <Space direction="vertical">
            <Button type="link" onClick={() => setOpenDrawer(false)}>
              <CloseOutlined style={{ fontSize: 25 }} />
            </Button>

            <Title level={4} type="secondary">
              Publi TV
            </Title>
          </Space>
        </div>
        <Divider dashed />
        <RenderMenu mode="inline" showIcon={true} />
      </Drawer>
    </>
  );
}
